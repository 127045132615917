import React from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { PressArticlePage } from '@talentinc/gatsby-theme-ecom/types/press'
import InquiryWidget from '@talentinc/gatsby-theme-ecom/components/InquiryWidget/InquiryWidget'
import RichText from '@talentinc/gatsby-theme-ecom/components/RichText'

const PressArticle: React.FC<PressArticlePage> = ({
  title,
  body,
  inquiryWidget: { contactName, email },
}: PressArticlePage) => {
  const { classes, cx } = useStyles()

  return (
    <>
      <Box className={cx(classes.box, classes.title)}>
        <Typography variant="h1">{title}</Typography>
      </Box>
      <Box className={classes.box}>
        <Box>
          <RichText rawBody={body.raw} references={body.references} />
        </Box>
        <Box className={classes.pullItUp}>
          <InquiryWidget
            contactName={contactName}
            email={email}
            lowerSectionClass={classes.hideSection}
          />
        </Box>
      </Box>
    </>
  )
}

const useStyles = makeStyles()((theme) => ({
  box: {
    display: 'grid',
    gridTemplateColumns: '7.5fr 2.5fr',
    gap: '8.8rem',
    padding: '4rem 6%',

    '@media (max-width: 1023px)': {
      gridTemplateColumns: '1fr',
      gap: '3rem',
    },

    '@media (max-width: 767px)': {
      gap: '2rem',
    },
  },
  title: {
    backgroundColor: theme.palette.newColors.greyBackground,
  },
  pullItUp: {
    marginTop: '-8.2rem',

    '@media (max-width: 1023px)': {
      marginTop: 0,
    },
  },
  hideSection: {
    '@media (max-width: 1023px)': {
      display: 'none',
    },
  },
}))

export default PressArticle
