import React from 'react'
import { graphql, PageProps } from 'gatsby'

import { PressArticlePage } from '@talentinc/gatsby-theme-ecom/types/press'
import { PageDataContextType } from 'types/page'
import SEO from '@talentinc/gatsby-theme-ecom/components/SEO'
import HeaderV2 from '@talentinc/gatsby-theme-ecom/components/HeaderV2/HeaderV2'
import Footer from '@talentinc/gatsby-theme-ecom/components/Footer/Footer'
import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'
import PressArticleTemplate from '@talentinc/gatsby-theme-ecom/components/Templates/PressArticle'

const PressArticle: React.FC<
  PageProps<
    { pressArticle: PressArticlePage } & PageDataContextType,
    { slug: string }
  >
> = ({ data, pageContext: { slug } }) => {
  const { pressArticle, ...pageData } = data
  const { title, seo } = pressArticle
  const imageURL = seo?.socialImage?.file?.url
  let description = null

  if (seo) {
    description = seo.description.description
  }

  const modifiedArticlePage = {
    ...pressArticle,
    // We use the transformed `slug` from the `pageContext`.
    // Please refer to the transformData callback for this page creation.
    slug,
    seo: { ...seo, description, imageURL },
  }
  return (
    <Layout pageTitle={title} pageData={pageData}>
      <SEO pressArticle={modifiedArticlePage} />
      <HeaderV2 />
      <PressArticleTemplate {...pressArticle} />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query PressReleasePage($id: String!, $brandName: String!) {
    pressArticle: contentfulPressItem(id: { eq: $id }) {
      ...PressArticle
    }
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: $brandName } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default PressArticle
